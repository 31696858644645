import React from 'react';

function TableHead({
  headers, onSelectAllBulkAction, allSelected, onSort, anyOutdated,
}) {
  return (
    <div className="thead">
      <div className="tr">
        { headers.map(header => (
          <div
            key={header.name}
            className={`th ${header.class || ''}`}
            data-name={header.name}
            onClick={() => { if (header.name !== 'po_number') onSort(header); }}
          >
            {
            header.name === 'po_number'
              ? (
                <label className="has-checkbox">
                  <input
                    className="count-selected"
                    type="checkbox"
                    checked={allSelected}
                    onChange={() => onSelectAllBulkAction()}
                  />
                  <span className="checkbox input-s" />
                </label>
              ) : null
            }
            <span onClick={() => { if (header.name === 'po_number') onSort(header); }}>
              {I18n.t(header.translation)}
            </span>
            {
              header.sortDirection !== ''
                ? (
                  <a className={`sort ${header.sortDirection}`} onClick={() => { if (header.name === 'po_number') onSort(header); }} />
                ) : null
            }
          </div>
        )) }
        <div className="th sticky-right" />
        {
          anyOutdated && <div className="th sticky-right" />
        }
      </div>
    </div>
  );
}

export default TableHead;
