import { makeGetRequest, makePutRequest } from '../components/shared/api';

class ReceiveGoodsService {
  static index = (params = '') => makeGetRequest(`/api/receive_goods${params}`)
    .then(response => response.data)

  static filters = (params = '') => makeGetRequest(`/api/receive_goods/filters${params}`)
    .then(response => response.data)

  static markAsRecevived = id => makePutRequest(`/api/receive_goods/${id}/mark_as_received`)
    .then(response => response.data)

  static bulkMarkAsRecevived = ids => makePutRequest('/api/receive_goods/bulk_mark_as_received', { ids })
    .then(response => response.data)

  static exportUrl = format => new URL(`${window.location.origin}/api/receive_goods/receive_goods_export.${format}${window.location.search}`);
}

export default ReceiveGoodsService;
