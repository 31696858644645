import React, { useEffect, useState, useRef } from 'react';
import ReceiveGoodsService from '../../services/ReceiveGoodsService';
import ReceiveGoodsTable from './receive_goods_table';
import * as toast from '../shared/toast';
import {
  urlParamsSetup, initSelectedSupplier, initSelectedUser,
  initSelectedBudget, initSelectedLocation, setUrlFilterParams,
  newUrlQueries,
} from './filters_helper';
import HeaderModel from '../../models/header_model';

function ReceiveGoods() {
  let urlParams = urlParamsSetup();
  const pageRendered = useRef(false);
  const baseHeaders = [
    { name: 'po_number', translation: 'purchase_orders.po_number' },
    { name: 'date', translation: 'purchase_orders.date_raised' },
    { name: 'supplier', translation: 'purchase_orders.supplier' },
    { name: 'budget', translation: 'purchase_orders.budget' },
    { name: 'location', translation: 'purchase_orders.location' },
    { name: 'description', translation: 'purchase_orders.description' },
    { name: 'amount', translation: 'purchase_orders.amount', class: 'number price' },
    { name: 'expected_delivery_date', translation: 'purchase_orders.expected_delivery_date', class: 'center' },
    { name: 'confirmed_delivery_date', translation: 'purchase_orders.confirmed_delivery_date', class: 'center' },
    { name: 'received', translation: 'purchase_orders.received', class: 'center' },
    { name: 'status', translation: 'purchase_orders.status', class: 'has-status' },
    { name: 'raised_by', translation: 'purchase_orders.raised_by' },
    { name: 'comments', translation: 'purchase_orders.comments', class: 'center' },
  ].map((header) => {
    if (urlParams.order.by === header.name) {
      const newHeader = new HeaderModel(header);
      newHeader.sortDirection = urlParams.order.type;
      return newHeader;
    }
    return new HeaderModel(header);
  });
  const [currentFilters, setCurrentFilters] = useState({
    selectedSupplier: initSelectedSupplier(urlParams),
    selectedUser: initSelectedUser(urlParams),
    selectedBudget: initSelectedBudget(urlParams),
    selectedLocation: initSelectedLocation(urlParams),
  });
  const [state, setState] = useState({
    purchaseOrders: [],
    selectedPurchaseOrders: [],
    suppliers: [],
    users: [],
    budgets: [],
    locations: [],
    showBulkActionBar: false,
    allSelected: false,
    isLoading: false,
    sortHeader: null,
    selectedPurchaseOrdersCount: 0,
    anyOutdated: false,
    pages: 1,
    currentPage: urlParams.page ? parseInt(urlParams.page) : 1,
    per: urlParams.per ? parseInt(urlParams.per) : 20,
    headers: baseHeaders,
    isMobileFilterModalOpen: false,
    showCompletedPurchaseOrders: false,
  });

  useEffect(() => {
    const finalQuery = newUrlQueries(urlParams);
    setState(prevState => ({ ...prevState, isLoading: true }));
    ReceiveGoodsService.index(finalQuery)
      .then(({
        purchase_orders, any_outdated, pages,
      }) => {
        setState(prevState => ({
          ...prevState,
          purchaseOrders: purchase_orders,
          isLoading: false,
          anyOutdated: any_outdated,
          pages,
        }));
        pageRendered.current = true;
      });
    updateFilters(finalQuery);
  }, []);

  useEffect(() => {
    if (pageRendered.current) {
      sortBy(state.sortHeader);
    }
  }, [state.headers]);

  useEffect(() => {
    if (pageRendered.current) {
      updatePurchaseOrders();
    }
  }, [currentFilters]);

  useEffect(() => {
    if (pageRendered.current) {
      updatePurchaseOrders(true);
    }
  }, [state.currentPage, state.per, state.showCompletedPurchaseOrders]);

  const updateFilters = (finalQuery) => {
    ReceiveGoodsService.filters(finalQuery)
      .then(({
        suppliers, users, budgets, locations,
      }) => {
        setState(prevState => ({
          ...prevState,
          suppliers,
          users,
          budgets,
          locations,
        }));
      });
  };

  const sortBy = (sortByHeader) => {
    const { order } = urlParams;
    order.by = sortByHeader.name;
    order.type = sortByHeader.sortDirection;

    urlParams = { ...urlParams, order };
    updatePurchaseOrders();
  };

  const updatePurchaseOrders = (updatePageNumber = false) => {
    if (updatePageNumber) {
      setState(prevState => ({ ...prevState, isLoading: true }));
    } else {
      setState(prevState => ({ ...prevState, isLoading: true, currentPage: 1 }));
    }
    const filterParams = {
      updatePageNumber, currentPage, per, showCompletedPurchaseOrders, urlParams, currentFilters,
    };
    urlParams = setUrlFilterParams(filterParams);

    const finalQuery = newUrlQueries(urlParams);

    window.history.pushState({}, 'Receive Goods', `/purchase_orders/receive_goods${finalQuery}`);
    ReceiveGoodsService.index(finalQuery)
      .then(({
        purchase_orders, any_outdated, pages,
      }) => {
        setState(prevState => ({
          ...prevState,
          purchaseOrders: purchase_orders,
          isLoading: false,
          anyOutdated: any_outdated,
          pages,
        }));
      });
  };

  const setSelectedFilter = (filter_name, record) => {
    let newFilter = record;
    if (currentFilters[filter_name].id === record.id) { newFilter = {}; }
    setCurrentFilters(prevState => ({
      ...prevState,
      [filter_name]: newFilter,
    }));
  };

  const onSelectAllBulkAction = () => {
    const { allSelected, purchaseOrders, selectedPurchaseOrders } = state;
    const newSelectedPurchaseOrders = allSelected ? [] : [...new Set([...selectedPurchaseOrders, ...purchaseOrders])];
    setState(prevState => ({
      ...prevState,
      selectedPurchaseOrders: newSelectedPurchaseOrders,
      showBulkActionBar: !allSelected,
      allSelected: !allSelected,
      selectedPurchaseOrdersCount: newSelectedPurchaseOrders.length,
    }));
  };

  const onChangeBulkAction = (purchaseOrderId) => {
    const { purchaseOrders, selectedPurchaseOrders } = state;
    const selectedPurchaseOrder = purchaseOrders.find(po => po.id === purchaseOrderId);
    const alreadySelectedPurchaseOrder = selectedPurchaseOrders.find(({ id }) => id === purchaseOrderId);
    const newPurchaseOrders = alreadySelectedPurchaseOrder ? selectedPurchaseOrders.filter(({ id }) => id !== purchaseOrderId) : [...selectedPurchaseOrders, selectedPurchaseOrder];
    const newPurchaseOrdersCount = newPurchaseOrders.length;
    const allPurchaseOrdersSelected = newPurchaseOrdersCount === purchaseOrders.length;
    setState(prevState => ({
      ...prevState,
      selectedPurchaseOrders: newPurchaseOrders,
      selectedPurchaseOrdersCount: newPurchaseOrdersCount,
      showBulkActionBar: (newPurchaseOrdersCount > 0),
      allSelected: allPurchaseOrdersSelected,
    }));
  };

  const onSort = (sortHeader) => {
    if (['received', 'comments'].includes(sortHeader.name)) { return; }
    const { headers } = state;
    const newHeaders = headers.map((header) => {
      if (header.name === sortHeader.name) {
        return header.setSortDirection();
      }
      return header.clean();
    });

    setState(prevState => ({
      ...prevState,
      headers: newHeaders,
      sortHeader,
    }));
  };

  const onPageChange = (page) => {
    if (page !== '...') {
      urlParams.page = page;
      setState(prevState => ({
        ...prevState,
        currentPage: page,
      }));
    }
  };

  const onPerChange = (currentPage, per) => {
    urlParams.per = per;
    setState(prevState => ({
      ...prevState,
      currentPage,
      per,
    }));
  };

  const onToggleMobileFiltersModal = () => {
    setState(prevState => ({
      ...prevState,
      isMobileFilterModalOpen: !prevState.isMobileFilterModalOpen,
    }));
  };

  const onToggleShowCompletedPurchaseOrders = () => {
    const { showCompletedPurchaseOrders } = state;
    const newState = !showCompletedPurchaseOrders;
    urlParams.show_completed = newState;
    setState(prevState => ({
      ...prevState,
      showCompletedPurchaseOrders: newState,
    }));
  };

  const onMarkAsReceived = (id) => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    ReceiveGoodsService.markAsRecevived(id)
      .then(() => {
        toast.successToast(I18n.t('commons.messages.changes_saved'));
        updatePurchaseOrders();
      })
      .catch(() => {
        toast.errorToast(I18n.t('commons.messages.error'));
        setState(prevState => ({ ...prevState, isLoading: false }));
      });
  };

  const onBulkMarkAsReceived = () => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    const { selectedPurchaseOrders } = state;
    const selectedPurchaseOrdersIds = selectedPurchaseOrders.map(po => po.id);
    ReceiveGoodsService.bulkMarkAsRecevived(selectedPurchaseOrdersIds)
      .then(({ corrupted_purchase_orders }) => {
        const poNumbers = corrupted_purchase_orders.map(po => po.po_number);
        const poIds = corrupted_purchase_orders.map(po => po.id);
        const poIdsCount = poIds.length;
        if (poIdsCount > 0) {
          const { purchaseOrders } = state;
          const newSelectedPurchaseOrders = purchaseOrders.filter(po => poIds.includes(po.id));
          const newSelectedPurchaseOrdersCount = newSelectedPurchaseOrders.length;
          const allPurchaseOrdersSelected = newSelectedPurchaseOrdersCount === purchaseOrders.length;
          setState(prevState => ({
            ...prevState,
            selectedPurchaseOrders: newSelectedPurchaseOrders,
            selectedPurchaseOrdersCount: newSelectedPurchaseOrdersCount,
            showBulkActionBar: (newSelectedPurchaseOrdersCount > 0),
            allSelected: allPurchaseOrdersSelected,
          }));
          toast.warningToast(I18n.t('commons.messages.bulk_mark_as_received_warning', { poNumbers: poNumbers.join(', ') }));
        } else {
          setState(prevState => ({
            ...prevState,
            selectedPurchaseOrders: [],
            selectedPurchaseOrdersCount: 0,
            showBulkActionBar: false,
            allSelected: false,
          }));
          toast.successToast(I18n.t('commons.messages.changes_saved'));
        }
        updatePurchaseOrders();
      })
      .catch(() => {
        toast.errorToast(I18n.t('commons.messages.error'));
        setState(prevState => ({ ...prevState, isLoading: false }));
      });
  };

  const {
    purchaseOrders, suppliers, users, budgets, locations,
    showBulkActionBar, headers, isLoading, allSelected, selectedPurchaseOrdersCount, anyOutdated,
    selectedPurchaseOrders, isMobileFilterModalOpen, currentPage, pages, per, showCompletedPurchaseOrders,
  } = state;

  const props = {
    purchaseOrders,
    suppliers,
    users,
    budgets,
    locations,
    showBulkActionBar,
    headers,
    isLoading,
    allSelected,
    selectedPurchaseOrdersCount,
    anyOutdated,
    selectedPurchaseOrders,
    isMobileFilterModalOpen,
    currentPage,
    pages,
    per,
    showCompletedPurchaseOrders,
  };

  return (
    <ReceiveGoodsTable
      props={props}
      setSelectedFilter={setSelectedFilter}
      currentFilters={currentFilters}
      onBulkMarkAsReceived={onBulkMarkAsReceived}
      onSelectAllBulkAction={onSelectAllBulkAction}
      onChangeBulkAction={onChangeBulkAction}
      onSort={onSort}
      onPageChange={onPageChange}
      onPerChange={onPerChange}
      onToggleMobileFiltersModal={onToggleMobileFiltersModal}
      onToggleShowCompletedPurchaseOrders={onToggleShowCompletedPurchaseOrders}
      onMarkAsReceived={onMarkAsReceived}
    />
  );
}

export default ReceiveGoods;
