import React from 'react';
import MobileFilter from './mobile_filter';

function MobileFiltersModal({
  suppliers, users, budgets, locations,
  setSelectedFilter, filters, onToggleMobileFiltersModal, isMobileFilterModalOpen,
}) {
  const {
    selectedSupplier, selectedUser, selectedBudget, selectedLocation,
  } = filters;
  return (
    <div className={`has-filter has-popup mobile-filter ${isMobileFilterModalOpen ? 'active' : null}`}>
      <div className={`popup ${isMobileFilterModalOpen ? 'active' : null}`}>
        <div className="popup-content">
          <h3>{I18n.t('purchase_orders.filter_by')}</h3>
          <div className="grid space-2 grid-form">
            <MobileFilter
              records={suppliers}
              selectedRecord={selectedSupplier}
              placeholder={I18n.t('dropdowns.supplier')}
              filterName="selectedSupplier"
              setSelectedFilter={setSelectedFilter}
            />
            <MobileFilter
              records={users}
              selectedRecord={selectedUser}
              placeholder={I18n.t('dropdowns.user')}
              filterName="selectedUser"
              setSelectedFilter={setSelectedFilter}
            />
            <MobileFilter
              records={budgets}
              selectedRecord={selectedBudget}
              placeholder={I18n.t('dropdowns.budgets')}
              filterName="selectedBudget"
              setSelectedFilter={setSelectedFilter}
            />
            <MobileFilter
              records={locations}
              selectedRecord={selectedLocation}
              placeholder={I18n.t('dropdowns.location')}
              filterName="selectedLocation"
              setSelectedFilter={setSelectedFilter}
            />
          </div>
        </div>
        <label className="popup-backdrop" onClick={onToggleMobileFiltersModal} />
      </div>
    </div>
  );
}

export default MobileFiltersModal;
