import React from 'react';
import ExportButton from './export_button';
import Filter from './filter';

export default function FilterBar({
  suppliers, users, budgets, locations,
  setSelectedFilter, filters,
}) {
  const {
    selectedSupplier, selectedUser, selectedBudget, selectedLocation,
  } = filters;
  return (
    <div className="table-actions">
      <div className="filters t-under-hide">
        <span>{I18n.t('purchase_orders.filter_by')}</span>
        <Filter
          records={suppliers}
          selectedRecord={selectedSupplier}
          placeholder={I18n.t('dropdowns.supplier')}
          filterName="selectedSupplier"
          setSelectedFilter={setSelectedFilter}
        />
        <Filter
          records={users}
          selectedRecord={selectedUser}
          placeholder={I18n.t('dropdowns.user')}
          filterName="selectedUser"
          setSelectedFilter={setSelectedFilter}
        />
        <Filter
          records={budgets}
          selectedRecord={selectedBudget}
          placeholder={I18n.t('dropdowns.budgets')}
          filterName="selectedBudget"
          setSelectedFilter={setSelectedFilter}
        />
        <Filter
          records={locations}
          selectedRecord={selectedLocation}
          placeholder={I18n.t('dropdowns.location')}
          filterName="selectedLocation"
          setSelectedFilter={setSelectedFilter}
        />
      </div>
      <ExportButton />
    </div>
  );
}
