import React from 'react';
import uuid from 'uuid/v4';

export default function Filter({
  records, selectedRecord, placeholder, filterName, setSelectedFilter,
}) {
  return (
    <span className={`select input-xs filter-select can-unselect ${selectedRecord.id ? 'selected' : ''}`}>
      <input type="checkbox" />
      <div className="select-content">
        {
          records.map(record => (
            <label
              key={record.id}
              className={`${selectedRecord.id === record.id ? 'checked' : ''}`}
              onClick={() => setSelectedFilter(filterName, record)}
            >
              {record.name}
            </label>
          ))
        }
        <label
          key={uuid()}
          onClick={() => setSelectedFilter(filterName, {})}
        >
          {I18n.t('commons.filters.all')}
        </label>
        <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={placeholder} />
      </div>
    </span>
  );
}
