import React from 'react';

export default function MobileFilter({
  records, selectedRecord, placeholder, filterName, setSelectedFilter,
}) {
  return (
    <div className="row">
      <div className="cell">
        <span key="states" className="select input-xs filter-select can-unselect">
          <input type="checkbox" />
          <div className="select-content ">
            {
              records.map(record => (
                <React.Fragment key={record.id}>
                  <input
                    autoComplete="off"
                    id={`webfilter_project_${record.id}`}
                    type="radio"
                    checked={selectedRecord.id === record.id}
                  />
                  <label onClick={() => setSelectedFilter(filterName, record)}>{record.name}</label>
                </React.Fragment>
              ))
            }
            <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={placeholder} />
          </div>
        </span>
      </div>
    </div>
  );
}
