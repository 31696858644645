import React from 'react';
import FilterBar from './filter_bar';
import BulkActionBar from './bulk_action_bar';
import Loader from '../loader';
import TableHead from './table_head';
import ReceiveGoodRow from './receive_good_row';
import MobileFiltersModal from './mobile_filters_modal';
import Modal from '../shared/modal';
import PaginateWithDots from '../item_list/paginate_with_dots';
import SelectUpTo from '../item_list/select_up_to';

const ReceiveGoodsTable = ({
  props, setSelectedFilter, currentFilters, onBulkMarkAsReceived, onSelectAllBulkAction, onChangeBulkAction, onSort,
  onPageChange, onPerChange, onToggleMobileFiltersModal, onToggleShowCompletedPurchaseOrders, onMarkAsReceived,
}) => {
  const {
    purchaseOrders, suppliers, users, budgets, locations,
    showBulkActionBar, headers, isLoading, allSelected, selectedPurchaseOrdersCount, anyOutdated,
    selectedPurchaseOrders, isMobileFilterModalOpen, currentPage, pages, per, showCompletedPurchaseOrders,
  } = props;
  const dotsNumber = headers.length + 1;
  const selectedPurchaseOrdersIds = selectedPurchaseOrders.map(po => po.id);

  const _renderInputs = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <input id={`${prefix}_column_${i}`} name={`${prefix}_table`} key={`${prefix}input_column_${i}`} defaultChecked={i === 1} type="radio" readOnly />
    ))
  );

  const _renderLabels = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <label htmlFor={`${prefix}_column_${i}`} key={`${prefix}label_column_${i}`} />
    ))
  );

  const _renderDots = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <div className="dot" key={`${prefix}dot_column_${i}`} />
    ))
  );

  const _renderColls = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <div className="col" key={`${prefix}col_column_${i}`} />
    ))
  );

  return (
    <div className="tile with-table">
      <div className="tile-header">
        <h1>{I18n.t('purchase_orders.partials.receive_goods_body.receive_goods')}</h1>
      </div>
      <div className="tile-content">
        {
          !showBulkActionBar && (
            <FilterBar
              suppliers={suppliers}
              users={users}
              budgets={budgets}
              locations={locations}
              setSelectedFilter={setSelectedFilter}
              filters={currentFilters}
            />
          )
        }
        <div className="has-bulk">
          {
            showBulkActionBar && (
              <BulkActionBar
                selectedPurchaseOrdersCount={selectedPurchaseOrdersCount}
                onSelectAllBulkAction={onSelectAllBulkAction}
                onBulkMarkAsReceived={onBulkMarkAsReceived}
                disabled={showCompletedPurchaseOrders}
              />
            )
          }
          <div className={`table-fluid table-scrollable table-column-${dotsNumber} ${isLoading ? ' has-loading' : ''}`}>
            <Loader />
            {_renderInputs(dotsNumber)}
            <div className="table-header with-filters">
              <div className="mobile-filters">
                <Modal>
                  <MobileFiltersModal
                    suppliers={suppliers}
                    users={users}
                    budgets={budgets}
                    locations={locations}
                    setSelectedFilter={setSelectedFilter}
                    filters={currentFilters}
                    isMobileFilterModalOpen={isMobileFilterModalOpen}
                    onToggleMobileFiltersModal={onToggleMobileFiltersModal}
                  />
                </Modal>
                <label onClick={onToggleMobileFiltersModal}>
                  <i className="icon-filter_list" />
                </label>
              </div>
              <div className="table-nav">
                {_renderLabels(dotsNumber)}
                {_renderDots(dotsNumber)}
              </div>
            </div>
            <div className="table table-sortable">
              <div className="colgroup">
                {_renderColls(dotsNumber)}
              </div>
              <TableHead
                headers={headers}
                onSelectAllBulkAction={onSelectAllBulkAction}
                allSelected={allSelected}
                onSort={onSort}
                anyOutdated={anyOutdated}
              />
              <div className="tbody">
                { purchaseOrders.map(purchaseOrder => (
                  <ReceiveGoodRow
                    key={purchaseOrder.id}
                    purchaseOrder={purchaseOrder}
                    onChangeBulkAction={onChangeBulkAction}
                    anyOutdated={anyOutdated}
                    selectedPurchaseOrdersIds={selectedPurchaseOrdersIds}
                    onMarkAsReceived={onMarkAsReceived}
                    markAsreceivedDisabled={showCompletedPurchaseOrders}
                  />
                )) }
              </div>
            </div>
          </div>
        </div>
        <div className="tile-footer">
          {
            showCompletedPurchaseOrders ? (
              <a className="link" onClick={onToggleShowCompletedPurchaseOrders}>
                {I18n.t('purchase_orders.hide_completed_po')}
              </a>
            ) : (
              <a className="link" onClick={onToggleShowCompletedPurchaseOrders}>
                {I18n.t('purchase_orders.show_completed_po')}
              </a>
            )
          }
          <div className="last">
            <SelectUpTo onPerChange={onPerChange} selected={per} />
            <PaginateWithDots onPageChange={onPageChange} numberPages={pages} selected={currentPage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiveGoodsTable;
