import React from 'react';
import ReceiveGoodsIndex from './receive_goods/receive_goods';

export default function ReceiveGoods() {
  return (
    <div className="grid">
      <div className="cell-16">
        <ReceiveGoodsIndex />
      </div>
    </div>
  );
}
