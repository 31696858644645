import React from 'react';

function BulkActionBar({
  selectedPurchaseOrdersCount, onBulkMarkAsReceived, onSelectAllBulkAction, disabled,
}) {
  return (
    <div className="table-bulk" style={{ display: 'flex' }}>
      <div className="actions">
        <div className="items items-divided">
          <span>
            <span className="selected" data-selected="selected">
              {`${selectedPurchaseOrdersCount} Selected`}
            </span>
          </span>
          <span>
            <span className="select input-s select-transparent display-inline-block">
              <input type="checkbox" disabled={disabled} />
              <div className="select-content">
                <label onClick={onBulkMarkAsReceived}>{I18n.t('purchase_orders.mark_as_received')}</label>
                <div className="placeholder" data-placeholder="Bulk actions" />
              </div>
            </span>
          </span>
        </div>
      </div>
      <label className="icon-close" htmlFor="table-bulk" onClick={onSelectAllBulkAction} />
    </div>
  );
}

export default BulkActionBar;
