export const urlParamsSetup = () => {
  const params = URI(window.location.href).query(true);

  const filters = {};

  Object.keys(params).map((key) => {
    const value = Array.isArray(params[key]) ? params[key] : [params[key]];

    return filters[key.replace('[', '').replace(']', '')] = value;
  });

  const order = filters.sortby ? { by: filters.sortby.join(''), type: filters.sorttype.join('') } : {};
  const { page, per, show_completed } = filters;
  delete filters.page;
  delete filters.per;
  delete filters.show_completed;
  delete filters.sortby;
  delete filters.sorttype;

  return {
    filters, order, page, per, show_completed,
  };
};

export const initSelectedSupplier = ({ filters }) => {
  if (filters.supplier_id) {
    const supplier_id = parseInt(filters.supplier_id[0]);
    return { id: supplier_id, value: supplier_id, filter_param: 'supplier_id' };
  }
  return {};
};

export const initSelectedUser = ({ filters }) => {
  if (filters.user_id) {
    const user_id = parseInt(filters.user_id[0]);
    return { id: user_id, value: user_id, filter_param: 'user_id' };
  }
  return {};
};

export const initSelectedBudget = ({ filters }) => {
  if (filters.budget_id) {
    const budget_id = parseInt(filters.budget_id[0]);
    return { id: budget_id, value: budget_id, filter_param: 'budget_id' };
  }
  return {};
};

export const initSelectedLocation = ({ filters }) => {
  if (filters.delivery_address_id) {
    const delivery_address_id = parseInt(filters.delivery_address_id[0]);
    return { id: delivery_address_id, value: delivery_address_id, filter_param: 'delivery_address_id' };
  }
  return {};
};

export const setUrlFilterParams = ({
  updatePageNumber, currentPage, per, showCompletedPurchaseOrders, currentFilters, urlParams,
}) => {
  const {
    selectedSupplier, selectedUser, selectedBudget, selectedLocation,
  } = currentFilters;
  const newUrlParams = urlParams;
  delete newUrlParams.filters.supplier_id;
  delete newUrlParams.filters.user_id;
  delete newUrlParams.filters.budget_id;
  delete newUrlParams.filters.delivery_address_id;
  delete newUrlParams.page;
  delete newUrlParams.per;
  delete newUrlParams.show_completed;

  if (selectedSupplier.id) {
    newUrlParams.filters.supplier_id = [selectedSupplier.id];
  }
  if (selectedUser.id) {
    newUrlParams.filters.user_id = [selectedUser.id];
  }
  if (selectedBudget.id) {
    newUrlParams.filters.budget_id = [selectedBudget.id];
  }
  if (selectedLocation.id) {
    newUrlParams.filters.delivery_address_id = [selectedLocation.id];
  }
  if (updatePageNumber) {
    newUrlParams.page = currentPage;
  }
  if (per) {
    newUrlParams.per = per;
  }
  if (showCompletedPurchaseOrders) {
    newUrlParams.show_completed = showCompletedPurchaseOrders;
  }
  return newUrlParams;
};

export const newUrlQueries = (urlParams) => {
  const {
    filters, order, page, per, show_completed,
  } = urlParams;

  const mappedFilters = [];
  for (const [key, value] of Object.entries(filters)) {
    value.forEach(val => mappedFilters.push(`${key}[]=${val}`));
  }

  const queries = [
    mappedFilters.join('&'),
    order.by ? `sort[by]=${order.by}&sort[type]=${order.type}` : '',
    page ? `page=${page}` : '',
    per ? `per=${per}` : '',
    show_completed ? 'show_completed=true' : '',
  ];

  const query = queries.filter(q => q).join('&');

  return (query.length > 0) ? `?${query}` : '';
};
