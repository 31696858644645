import React from 'react';
import ReceiveGoodsService from '@/services/ReceiveGoodsService';

export default function ExportButton() {
  return (
    <div className="has-popup">
      <input type="checkbox" />
      <a className="button button-primary button-s" href="" onClick={e => e.preventDefault()}>
        {I18n.t('commons.filters.export')}
      </a>
      <ul className="popup menu top-left min-width-1">
        <li className="item">
          <a className="anchor" href={ReceiveGoodsService.exportUrl('csv')}>
            {I18n.t('supplier_items.import_modal.csv')}
          </a>
        </li>
        <li className="item">
          <a className="anchor" href={ReceiveGoodsService.exportUrl('xlsx')}>
            {I18n.t('supplier_items.import_modal.xlsx')}
          </a>
        </li>
      </ul>
    </div>
  );
}
